<template>
    <div>
        <vx-card class="h-full">
            <h3>Crear nuveo cupón</h3>
            
            <form @submit.prevent="saveCoupon()">
                <div class="vx-row">
                    <!-- coupon code -->
                    <div class="vx-col w-full mt-10">
                        <vs-input
                            v-model="coupon.code"
                            label="Código"
                            val-icon-danger="clear"
                            :danger="!!validate.skuError"
                            :danger-text="validate.skuError"
                            class="w-full"
                            autofocus
                            required
                        />
                    </div>

                    <!-- coupon name -->
                    <div class="vx-col w-1/2 mt-5">
                        <vs-input
                            v-model="coupon.name"
                            label="Nombre"
                            val-icon-danger="clear"
                            class="w-full"
                            :danger="!!validate.nameError"
                            :danger-text="validate.nameError"
                            required
                        />
                    </div>

                    <!-- coupon price -->
                    <div class="vx-col w-1/2 mt-5">
                        <vs-input
                            v-model="coupon.price"
                            label="Precio"
                            val-icon-danger="clear"
                            icon="attach_money"
                            class="w-full"
                            :danger="!!validate.priceError"
                            :danger-text="validate.priceError"
                            type="number"
                            required
                        />
                    </div>

                    <!-- coupon text -->
                    <div class="vx-col w-full mt-5">
                        <vs-textarea
                            v-model="coupon.text"
                            label="Descripción"
                            class="w-full"
                            :danger="!!validate.descriptionError"
                            :danger-text="validate.descriptionError"
                            required
                        />
                    </div>

                    <!-- start date -->
                    <div class="vx-col w-1/2">
                        <label for="date_init">Seleccione la fecha de inicio</label><br/>
                        <flat-pickr
                            v-model="coupon.start_date_prog"
                            :config="configFromdateTimePicker"
                            placeholder="Fecha de inicio"
                            @on-change="onFromChange"
                            id="date_init"
                            class="vs-input--input"
                        />
                    </div>

                    <!-- start hour -->
                    <div class="vx-col w-1/2">
                        <label for="hour_init">Seleccione la hora de inicio</label><br/>
                        <flat-pickr
                            v-model="coupon.start_hour"
                            :config="configTime"
                            placeholder="Hora de inicio"
                            @on-change="validateInitHour"
                            id="hour_init"
                            class="vs-input--input"
                        />
                    </div>

                    <!-- end date -->
                    <div class="vx-col w-1/2 mt-5">
                        <label for="end_date">Seleccione la fecha de finalización</label><br/>
                        <flat-pickr
                            v-model="coupon.end_date_prog"
                            :config="configTodateTimePicker"
                            placeholder="Fecha de finalización"
                            @on-change="onToChange"
                            id="end_date"
                            class="vs-input--input"
                        />
                    </div>

                    <!-- end hour -->
                    <div class="vx-col w-1/2 mt-5">
                        <label for="end_hour">Seleccione la hora de finalización</label><br/>
                        <flat-pickr
                            v-model="coupon.end_hour"
                            :config="configTime"
                            placeholder="Hora de finalización"
                            @on-change="validateEndHour"
                            id="end_hour"
                            class="vs-input--input"
                        />
                    </div>
                </div>

                <vs-collapse>
                    <vs-collapse-item>
                        <div slot="header">
                            <feather-icon icon="PlusIcon" class="align-middle"/>
                            Más opciones
                        </div>
                        <div class="vx-row mb-5">
                            <!-- coupon minimum purchase -->
                            <div class="vx-col w-1/2">
                                <vs-input
                                    v-model="coupon.minimum_purchase"
                                    label="Precio mínimo de compra"
                                    icon="attach_money"
                                    class="w-full"
                                    type="number"
                                />
                            </div>

                            <!-- visibilidad -->
                            <div class="vx-col w-1/2">
                                <vs-select
                                    class="w-full"
                                    label="Privacidad"
                                    v-model="coupon.view"
                                >
                                    <vs-select-item :key="item.id" :value="item.id" :text="item.value" v-for="item in viewOptions" />
                                </vs-select>
                            </div>

                            <!-- coupon identification -->
                            <div class="vx-col w-1/2 mt-5">
                                <vs-input
                                    v-model="coupon.identification"
                                    label="Número de Identificación de cliente"
                                    class="w-full"
                                />
                            </div>

                            <!-- coupon shop -->
                            <div class="vx-col w-1/2 mt-5">
                                <label for="shop_id">Seleccione una tienda</label><br/>
                                <v-select
                                    :options="stores"
                                    :reduce="item => item.id"
                                    :appendToBody="true"
                                    v-model="coupon.shop_id"
                                    label="name"
                                    id="shop_id"
                                    placeholder="Seleccione una tienda"
                                >
                                </v-select>
                            </div>

                            <!-- status -->
                            <div class="vx-col w-1/2 mt-5">
                                <vs-select
                                    class="w-full"
                                    label="Estado"
                                    v-model="coupon.status"
                                >
                                    <vs-select-item :key="item.id" :value="item.id" :text="item.value" v-for="item in statusOptions" />
                                </vs-select>
                            </div>
                        </div>
                    </vs-collapse-item>
                </vs-collapse>

                <div class="flex justify-between mt-10">
                    <div></div>
                    <button type="submit" class="vs-component vs-button rounded-full vs-button-null vs-button-filled bg-primary">
                        Guardar
                    </button>
                </div>

            </form>
        </vx-card>
    </div>
</template>

<script lang="ts">
import Vue       from 'vue';
import vSelect   from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import { Validation } from '../../../plugins/validation';
import utils          from '../../../plugins/utils';


export default Vue.extend({

    data() {
        return {
            validate: new Validation(),
            configFromdateTimePicker : {
                minDate: null,
                maxDate: null
            },
            configTodateTimePicker: {
                minDate: null
            },
            utils,
            configTime : {
                enableTime: true,
                enableSeconds: true,
                noCalendar: true,
                dateFormat: "H:i:s",
            },
            viewOptions : [
                { id: 0, value: 'No' },
                { id: 1, value: 'Sí' },
            ],
            statusOptions : [
                { id: 0, value: 'Inactivo' },
                { id: 1, value: 'Activo' },
                { id: 2, value: 'Privado' },
            ],
        }
    },

    created() {
        this.setDates();
        this.$vs.loading();
        this.$store.dispatch('stores/getAllStores').then(() => this.$vs.loading.close() );
    },

    beforeDestroy() {
        this.$store.commit('promotions/CLEAR_COUPON');
    },

    watch : {
        'coupon.code'(val) { this.validate.validateSKU( val ); },
        'coupon.name'(val) { this.validate.validateName( val ); },
        'coupon.price'(val) { this.validate.validatePrice( val ); },
        'coupon.text'(val) { this.validate.validateDescription( val ); },
    },

    methods : {
        async saveCoupon() {
            const isValidSKU = this.validate.validateSKU( this.coupon.code );
            const isValidName = this.validate.validateName( this.coupon.name );
            const isValidPrice = this.validate.validatePrice( this.coupon.price );
            const isValidDescription = this.validate.validateDescription( this.coupon.text );
            
            if( !isValidSKU || !isValidName || !isValidPrice || !isValidDescription ) {
                this.$vs.notify({
                    title: 'Valide los campos antes de continuar',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7100,
                });
                return;
            }

            this.$vs.loading();

            const payload = {
                ...this.coupon,
                end_hour: this.coupon.end_hour.replace(' ', ''),
                start_hour: this.coupon.start_hour.replace(' ', ''),
            }
            
            const res = await this.$store.dispatch('promotions/saveCoupon', payload);
            
            this.$vs.loading.close();
            
            if( res ) this.$router.push({ path : '/app/coupons/list' });
        },

        validateInitDate( dateStr='' ) {
            const dateSplit = dateStr.split('-');
            const now = new Date();
            const nowTs = now.getTime();
            const hour = utils.dateToStr(now).substring(10, 18);
            const hrSplit = hour.split(':');

            const dateTs = new Date(
                parseInt(dateSplit[0]),
                parseInt(dateSplit[1]) - 1,
                parseInt(dateSplit[2])
            ).setHours( parseInt(hrSplit[0]), parseInt(hrSplit[1] + 2) );

            if( nowTs > dateTs ) {
                this.$vs.notify({
                    title: 'Seleccione una fecha superior a la actual',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7100,
                });

                setTimeout(() => {
                    this.coupon.start_date_prog = utils.dateToStr(now).substring(0, 10);
                    this.$set(this.configTodateTimePicker, 'minDate', this.coupon.start_date_prog);
                }, 1);
            }
            
        },

        validateInitHour(ev) {
            const dateSplit = this.coupon.start_date_prog.split('-');
            const date = new Date(dateSplit[0], dateSplit[1] -1, dateSplit[2]);
            date.setHours( ev[0].getHours() );
            date.setMinutes( ev[0].getMinutes() );
            date.setSeconds( ev[0].getSeconds() );
            
            const nowTs = new Date().getTime();
            const dateTs = date.getTime();

            if( nowTs > dateTs ) {
                this.$vs.notify({
                    title: 'Seleccione una hora superior a la actual',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7100,
                });

                const fiveMinutes = new Date();
                fiveMinutes.setMinutes( new Date().getMinutes() +20 );

                const fiveMinutesStr = this.utils.dateToStr( fiveMinutes );
                const fiveMinutesSub = fiveMinutesStr.substring(10, 19);

                setTimeout(() => {
                    this.coupon.start_hour = fiveMinutesSub;
                }, 3000);
                
            }            
        },

        validateEndHour(ev) {
            const dateInitSplit = this.coupon.start_date_prog.split('-');
            const hourInitSplit = this.coupon.start_hour.split(':');
            const dateInit = new Date(dateInitSplit[0], dateInitSplit[1] -1, dateInitSplit[2]);
            dateInit.setHours( hourInitSplit[0] );
            dateInit.setMinutes( hourInitSplit[1] );
            dateInit.setSeconds( hourInitSplit[2] );

            const dateInitTs = dateInit.getTime();
            
            const dateEndSplit = this.coupon.end_date_prog.split('-');
            const dateEnd = new Date(dateEndSplit[0], dateEndSplit[1] -1, dateEndSplit[2]);
            dateEnd.setHours( ev[0].getHours() );
            dateEnd.setMinutes( ev[0].getMinutes() );
            dateEnd.setSeconds( ev[0].getSeconds() );
            
            const endDateTs = dateEnd.getTime();
            

            if( dateInitTs > endDateTs ) {
                this.$vs.notify({
                    title: 'Seleccione una hora superior a la de inicio',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7100,
                });

                const fiveMinutes = new Date( dateInitTs );
                fiveMinutes.setMinutes( new Date().getMinutes() + 90 );

                const fiveMinutesStr = this.utils.dateToStr( fiveMinutes );
                const fiveMinutesSub = fiveMinutesStr.substring(10, 19);

                setTimeout(() => {
                    this.coupon.end_hour = fiveMinutesSub;
                }, 3000);
                
            }
        },

        /* eslint-disable */
        onFromChange(selectedDates, dateStr, instance) {
            this.validateInitDate(dateStr);
            this.$set(this.configTodateTimePicker, 'minDate', dateStr);
        },

        /* eslint-disable */
        onToChange(selectedDates, dateStr, instance) {
            this.$set(this.configFromdateTimePicker, 'maxDate', dateStr);
        },

        setDates() {
            const now   = new Date();
            const endTs = new Date().setDate( new Date().getDate() + 30);
            const end   = new Date( endTs );
            
            this.coupon.start_date_prog = utils.dateToStr(now).substring(0, 10);
            this.coupon.end_date_prog   = utils.dateToStr(end).substring(0, 10);

            this.$set(this.configTodateTimePicker, 'minDate', this.coupon.start_date_prog);
            this.$set(this.configFromdateTimePicker, 'maxDate', this.coupon.end_date_prog);

            const fiveMinutes = new Date();
            fiveMinutes.setMinutes( new Date().getMinutes() + 20 );

            const fiveMinutesStr = this.utils.dateToStr( fiveMinutes );
            const fiveMinutesSub = fiveMinutesStr.substring(10, 19);
            
            this.coupon.start_hour = fiveMinutesSub;
            this.coupon.end_hour = fiveMinutesSub;
        },
    },
    
    computed : {
        coupon : {
            get() { // return coupon proxy
                const store = this.$store;
                const coupon = store.state.promotions.coupon;
                let couponProxy = new Proxy( coupon, {
                    set (obj, key, value) {
                        store.commit('promotions/SET_COUPON', {
                            ...obj,
                            [key]: value,
                        });    
                        return true
                    }
                });

                return couponProxy;
            },
            set( val ) { this.$store.commit('promotions/SET_COUPON', val); }
        },
        stores() {
            return this.$store.state.stores.stores;
        },
    },

    components : {
        flatPickr,
        vSelect,
    }
})
</script>
